import Layout from '../components/Layout';
import NotFound from '../components/NotFound';

export default function NotFoundPage() {
  return (
    <Layout>
      <NotFound />
    </Layout>
  );
}
